#landing-wrapper {
    height: 100vh;
    min-height: 750px;
    max-height: 920px;
    background: linear-gradient(to right, #13315C 50%, #0B2545 50%);
    display: flex;
    justify-content: center;
    align-items: stretch;
}

#landing-wrapper>div:first-of-type {
    position: relative;
    background-color: #13315C;
    color: white;
    width: 42%;
    max-width: 560px;
    padding: 51px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
}

#landing-wrapper>div:first-of-type p, #landing-wrapper>div:first-of-type h1 {
    max-width: 371px;
}

#landing-wrapper>div:first-of-type p {
    line-height: 32px;
    margin-top: 18px;
}

#landing-wrapper>div:first-of-type>span {
    position: absolute;
    width: calc(100% - 102px);
    bottom: 54px;
    display: flex;
    align-items: flex-end;
}

#landing-wrapper>div:first-of-type>span>a {
    margin-right: 26px;
}

#landing-wrapper>div:first-of-type>span>a>img {
    transition: 300ms;
}

#landing-wrapper>div:first-of-type>span>a>img:hover {
    transform: scale(1.2);
}

#landing-wrapper>div:first-of-type>span>div {
    flex-grow: 1;
}

#landing-wrapper>div:first-of-type>span>a:last-child>img {
    width: 100px;
    height: auto;
    margin-right: 0;
}

#landing-wrapper>div:nth-of-type(2) {
    box-sizing: border-box;
    background-color: #0B2545;
    width: 58%;
    max-width: 827px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

#landing-wrapper #resume-png {
    width: 27px;
    cursor: pointer;
}

@media (max-width: 1024px) {
    #landing-wrapper {
        background: none;
        flex-direction: column;
        max-height: none;
        height: auto;
    }
    #landing-wrapper>div:first-of-type {
        min-height: 300px;
        padding: 20px 51px;
    }
    #landing-wrapper>div:first-of-type, #landing-wrapper>div:nth-of-type(2) {
        width: 100%;
        max-width: none;
    }
    #landing-wrapper>div:first-of-type>span {
        position: relative;
        width: 100%;
        bottom: auto;
        margin-top: 30px;
        display: flex;
        align-items: flex-end;
    }
}

@media (max-width: 768px) {
    #landing-wrapper>div:nth-of-type(2) {
        flex-direction: column;
    }
}

@media (max-width: 640px) {
    #landing-wrapper>div:first-of-type>span>a:last-child>img {
        width: 75px;
        height: auto;
    }
}

@media (max-width: 500px) {
    #landing-wrapper>div:first-of-type>h1 {
        font-size: 60px;
    }
}