.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1, h2, h3, h4, h5, h6, p {
  font-family: 'Lato', sans-serif;
  margin: 0;
}

h1 {
  font-weight: 300;
  font-size: 96px;
  letter-spacing: -1.5px;
}

h2 {
  font-weight: 300;
  font-size: 60px;
  letter-spacing: -0.5px;
}

h3 {
  font-weight: 400;
  font-size: 48px;
  letter-spacing: 0;
}

h4 {
  font-weight: 400;
  font-size: 34px;
  letter-spacing: 0.25px;
}

h5 {
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0;
}

h6 {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.15px;
}

p.subtitle1 {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
}

p.subtitle2 {
  font-weight: 14px;
  font-size: 14px;
  letter-spacing: 0.1px;
}

p.body1 {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.5px;
}

p.body2 {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.25px;
}