.experience {
    display: flex;
    flex-direction: row;
}

.experience .experience-title-wrapper {
    width: 20%;
    max-width: 400px;
    min-width: 100px;
}

.experience .experience-title-wrapper .experience-title {
    writing-mode: vertical-rl;
    font-size: 110px;
    font-weight: 700;
    margin-top: 2rem;
    margin-left: 2rem;
    text-align: start;
}

.experience-content-wrapper {
    background-color: #134074;
    padding: 68px 0;
    flex-grow: 1;
}

.experience-content-inner-wrapper {
    width: 92%;
    max-width: 1328px;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
}

.experience-content-wrapper .experience-card {
    background-color: #0B2545;
    padding: 24px;
    width: 30%;
    min-width: 350px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    text-align: left;
    margin: 1rem;
}

.experience-content-wrapper .experience-card.full-width {
    width: 100%;
}

.experience-content-wrapper .experience-card h5 {
    color: #89DEFF;
}
.experience-content-wrapper .experience-card .subtitle2 {
    color: #DCDDA4;
    margin-top: 11px;
}
.experience-content-wrapper .experience-card .subtitle1 {
    color: #D88E73;
    margin-top: 10px;
    margin-bottom: 6px;
}
.experience-content-wrapper .experience-card .body1 {
    color: rgba(255, 255, 255, 0.8);
    margin-top: 14px;
    line-height: 21px;
}

.experience-content-wrapper .experience-card .experience-description {
    display: flex;
    align-items: flex-start;
    margin-top: 14px;
}

.experience-content-wrapper .experience-card .experience-description .dash {
    color: rgba(255, 255, 255, 0.8);
    margin-right: 8px;
}

.experience-content-wrapper .experience-card .experience-description .body1 {
    margin-top: 0;
}

.experience-content-wrapper .experience-card .code-snippet-wrapper {
    margin-top: 17px;
}

@media (max-width: 1500px) {
    .experience .experience-title-wrapper .experience-title {
        font-size: 70px;
    }
}

@media (max-width: 1300px) {
    .experience .experience-title-wrapper .experience-title {
        font-size: 50px;
    }
}

@media (max-width: 1023px) {
    .experience-content-inner-wrapper {
        flex-direction: column;
        justify-content: stretch;
        align-items: flex-start;
    }

    .experience-content-wrapper .experience-card {
        width: 100%;
        margin-bottom: 30px;
    }

    .experience-content-wrapper {
        padding-right: 30px;
    }

    .experience-content-wrapper .experience-card:last-child {
        margin-bottom: 0;
    }
}

@media (max-width: 520px) {
    .experience {
        flex-direction: column;
    }

    .experience .experience-title-wrapper {
        width: 100%;
        max-width: none;
    }

    .experience .experience-title-wrapper .experience-title {
        text-align: center;
        margin-left: 0;
        writing-mode: horizontal-tb;
    }

    .experience-content-wrapper {
        padding-top: 1rem;
        padding-right: 0;
    }

    .experience .experience-content-wrapper .experience-card {
        min-width: unset;
        width: auto;
        margin-left: 0;
        margin-bottom: 0;
    }
}

@media (max-width: 450px) {

    .experience .experience-title-wrapper .experience-title {
        font-size: 30px;
    }

    .experience-content-wrapper {
        padding-top: 1rem;
    }

    .experience-content-wrapper .experience-card {
        margin-right: 0;
        padding-right: 0;
    }

    .experience-content-wrapper .experience-card:last-child {
        margin-bottom: 0;
    }
}